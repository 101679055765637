<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
      <b-row>
        <b-col cols="12">
          <validation-provider name="Bölge Adı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Bölge Adı">
              <b-form-input
                class="rounded-0"
                ref="baslik"
                v-model="form.baslik"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />

              <b-form-invalid-feedback id="input-1-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center py-1">
            <label for="statu" class="font-weight-bold h6">Kullanım Durumu</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button squared block type="submit" :variant="form._id == null ? 'primary' : 'warning'">
            <feather-icon icon="CheckSquareIcon" class="mr-50" />
            <span class="align-middle">{{ form._id == null ? 'Ekle' : 'Güncelle' }}</span>
          </b-button>
          <b-button squared block type="submit" v-if="form._id" @click="resetForm">
            <feather-icon icon="CheckSquareIcon" class="mr-50" />
            <span class="align-middle">iptal</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import { diller } from '@core/mixins/ui/diller';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
export default {
  components: {
    vSelect,
  },
  props: {
    bolgeler: {
      type: Array,
      required: true,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },
  mixins: [diller],
  data: () => ({
    form: {
      _id: null,
      baslik: null,
      statu: true,
    },
  }),
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        _id: null,
        baslik: null,
        statu: true,
      };
      this.$emit('showLoading', false);
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
      this.$refs.baslik.focus();
    },
    onSubmit() {
      this.$emit('showLoading', true);
      const basrUrl = this.form._id == null ? 'bolgeEkle' : 'bolgeGuncelle';

      this.$store
        .dispatch(basrUrl, this.form)
        .then((res) => {
          if (res.data.success === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: this.form._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
              },
            });
            this.$emit('refresh', res.data.data);
            this.resetForm();
          }
        })
        .catch((err) => {
          if (err.success === false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text: err.message,
              },
            });
          }
        });
    },
  },
  watch: {
    updateData(val) {
      this.form._id = val._id;
      this.form.baslik = val.baslik;
      this.form.bolge_k_no = val.bolge_k_no;
      this.form.statu = val.statu;
    },
  },
};
</script>

<style lang="scss" scoped></style>
