<template>
  <b-list-group class="rounded-0">
    <div v-for="item in data" :key="item._id">
      <b-list-group-item
        class="d-flex justify-content-between align-items-center mb-25"
        :class="item.statu ? ' border-primary' : ' border-danger'"
      >
        <h6 class="mt-25">{{ item.baslik }}</h6>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <b-button-group>
              <b-button
                squared
                size="sm"
                :variant="item.statu ? 'success' : 'danger'"
                v-b-tooltip.hover.top="item.statu ? 'Aktif' : 'Pasif'"
              >
                <feather-icon icon="EyeIcon" v-if="item.statu" />
                <feather-icon icon="EyeOffIcon" v-else />
              </b-button>
              <b-button squared size="sm" variant="warning" v-b-tooltip.hover.top="'Güncelle'" @click="$emit('update', item)">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button squared size="sm" variant="danger" v-b-tooltip.hover.top="'Sil'" @click="handlerRemove(item.k_no)">
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-button-group>
          </div>
        </div>
      </b-list-group-item>
    </div>
  </b-list-group>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
    BCardNormal,
  },
  props: {
    data: {
      type: Array,
      required: false,
    },
  },
  methods: {
    handlerRemove(k_no) {
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('bolgeSil', k_no).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Kayit silinmiiştir.`,
                },
              });
              this.$emit('refresh', k_no);
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: res.data.message,
                },
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
