<template>
  <b-row>
    <b-col md="4">
      <b-card-normal title="Bölge Tanımlama" :showLoading="show">
        <template v-slot:body>
          <tanimlama :bolgeler="bolgeler" @showLoading="show = $event" @refresh="handlerGetData" :updateData="updateData" />
        </template>
      </b-card-normal>
    </b-col>
    <b-col md="8" order-sm="12">
      <b-card-normal title="Bölgeler" :showLoading="show">
        <template v-slot:body>
          <list :data="rows" @update="handlerUpdate($event)" @refresh="handlerGetData" />
        </template>
      </b-card-normal>
    </b-col>
  </b-row>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import Tanimlama from './component/tanimlama.vue';
import List from './component/list.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    BCardNormal,
    Tanimlama,
    List,
  },
  data: () => ({
    show: false,
    bolgeler: [],
    rows: [],
    updateData: {},
  }),
  computed: {
    ...mapGetters(['getBolgeler']),
  },
  async created() {
    await this.$store.dispatch('bolgeListele');
    this.handlerGetData();
  },
  methods: {
    async handlerGetData() {
      this.show = true;
      this.rows = [];
      this.bolgeler = [];

      const bolgeler = await this.getBolgeler;

      bolgeler.forEach((el) => {
        if (el.bolge_k_no == null) this.bolgeler.push({ bolge_k_no: el.k_no, baslik: el.baslik });
      });

      bolgeler.forEach((el, i) => {
        if (el.bolge_k_no == null) this.rows.push({ ...el, children: [] });
        if (el.bolge_k_no != null) {
          let index = this.rows.findIndex((x) => x.k_no == el.bolge_k_no);
          this.rows[index].children.push(el);
        }
      });
      this.show = false;
    },
    handlerUpdate(event) {
      this.updateData = event;
    },
  },
};
</script>

<style lang="scss" scoped></style>
